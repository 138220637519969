<template>
  <div class="app-context util-page">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <div class="context account">
      <div class="title">
        <div class="img-wrap">
          <img src="@/assets/images/ck_account.svg" />
        </div>
        <h3>휴면계정 전환 안내</h3>
      </div>
      <div class="info">
        <div class="article">
          <h5>주요내용</h5>
          <p>
            최근1년간 서비스를 이용한 이력이 없는 회원의 계정은 휴먼계정으로
            전화하고 회원 가입 시 수집한 개인정보는 별도 분리하여 보관합니다.
          </p>
          <p>(휴먼 해제 미 신청 시 당사에서는 해당 정보를 이용하지 않음)</p>
        </div>
        <div class="article">
          <h5>분리보관 개인정보</h5>
          <p>이름, 휴대폰 번호, 이메일, 주소 등</p>
        </div>
        <div class="article">
          <h5>시행일자</h5>
          <p>2021년2월8일(월) 이후</p>
          <p>(휴먼 해제 미 신청 시 당사에서는 해당 정보를 이용하지 않음)</p>
        </div>
        <div class="article">
          <h5>관련법령</h5>
          <p>
            정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조
            (개인정보의파기) 정보통신망 이용촉진 및 정보보호 등에 관한 법률
            시행령 제 16조(개인정보파기 등)
          </p>
        </div>
      </div>
      <div class="mention">
        <p></p>
        <p></p>
      </div>
      <div class="btn-wrap">
        <button type="button" class="active" @click="terminate()">
          휴먼해제
        </button>
      </div>
      <!--
      <div class="auth-notice">
        <h3><b>휴면계정 전환</b> 안내</h3>
      </div>
      <div class="law">
        <div class="item">
          <h5 class="i-title">주요내용</h5>
          <p>
            최근 1년간 서비스를 이용한 이력이 없는 회원의 계정은 휴면계정으로 전환하고
            회원 가입 시 수집한 개인정보는 별도 분리하여 보관합니다. (휴먼 헤제 미 신청 시
            당사에서는 해당 정보를 이용하지 않음)
          </p>
        </div>
        <div class="item">
          <h5 class="i-title">분리보관 개인정보</h5>
          <p>이름, 휴대폰 번호, 이메일, 주소 등</p>
        </div>
        <div class="item">
          <h5 class="i-title">시행일자</h5>
          <p>2015년 8월18일(화) 이후</p>
        </div>
        <div class="item">
          <h5 class="i-title">관련법령</h5>
          <p>
            정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조 (개인정보의파기)
            정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행령 제 16조 (개인정보파기
            등)
          </p>
        </div>
      </div>
      <p class="notice">
        회원님의 아이디가 휴면계정으로 전환되어도 휴먼해제 신청을 통해 휴면 상태를
        해제하실 수 있습니다. 앞으로도 회원님의 개인정보를 소중하게 관리하고, 보다 더
        안전하게 이용할 수 있도록 노력하겠습니다. 감사합니다.
      </p>
      <div class="restrict-btn-wrap">
        <button type="button" @click="terminate()">휴면해제</button>
      </div>
      -->
    </div>

    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
// import AppHeader from "@/components/AppHeader";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  components: {
    // AppHeader,
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      isChanged: false,
    };
  },
  created() {},
  mounted() {
    /** 
    this.$nextTick((_) => {
      this.$refs.appheader.callLoginPop(sessionStorage.getItem("destinedURL"), "fixing");
    });
     */
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapActions("util", ["RECOVERY"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    async terminate() {
      try {
        await this.RECOVERY().then(() => {
          this.isChanged = true;
          this.SET_POPUP(true);
          this.popupSet.title = "휴면계정 관리";
          this.popupSet.content = "휴면계정 복구가 완료되었습니다.";
          this.popupSet.popType = "custom";
          this.popupSet.cancelBtnText = undefined;
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/";
          this.popupSet.destination = true;
        });
      } catch (error) {
        //console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.$refs.appheader.clearLocalStorage();
          this.SET_POPUP(true);
          this.popupSet.title = "휴면계정 관리";
          this.popupSet.content = "서버통신장애로 요청이 실패했습니다.";
          this.popupSet.popType = "custom";
          this.popupSet.cancelBtnText = undefined;
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/";
          this.popupSet.destination = true;
        }
      }
    },
  },
  beforeDestroy() {
    if (!this.isChanged) {
      //console.log("상태 변경없이 페이지 이탈함");
      this.$refs.appheader.clearLocalStorage();
      this.$refs.appheader.initLogin();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/account";
.context {
  .auth-notice {
    h3 {
      font-family: "SUIT";
      font-size: 26px;
      line-height: 42px;
      margin: 0 0 30px 0;
      color: #474747;
      b {
        color: #146f83;
        font-family: "SUIT";
      }
    }
  }
  .law {
    margin: 0 0 30px 0;
    .item {
      display: flex;
      border: 0px solid #e8e8e8;
      border-width: 1px 0 0 0;
      align-items: center;
      &:last-of-type {
        border-width: 1px 0 1px 0;
        margin: 0;
      }

      h5.i-title {
        font-family: "SUIT";
        font-weight: normal;
        color: #474747;
        background: #f8f8f8;
        min-width: 205px;
        margin: 0 20px 0 0;
        padding: 0 0 0 15px;
        line-height: 87px;
        font-size: 19px;
      }
      p {
        font-family: "SUIT";
        background: #fff;
        width: calc(100% - 225px);
        line-height: 29px;
      }
    }
  }
  p.notice {
    font-family: "SUIT";
    margin: 0 0 30px 0;
  }
  .restrict-btn-wrap {
    button {
      padding: 0;
      border: 0;
      background: #146f83;
      width: 145px;
      font-family: "SUIT";
      height: 54px;
      line-height: 54px;
      font-size: 22px;
      color: #fff;
      cursor: pointer;
    }
  }
}
.info {
  background-color: whitesmoke !important;
}
</style>
